import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import { Layout, Text } from '../components'

export default () => {
  const { text } = useStaticQuery(graphql`
  {
    text: contentfulText(tid: {eq: "cherepovets-details"}) {
      title
      content {
        json
      }
    },
  }`)
    return (
        <Layout title={text.title}>
            <Text content={text.content.json} />
        </Layout>
    )
}
